import { Dispatch, SetStateAction, useCallback, useState } from "react";

export interface UseToggleStateCallbacks {
  toggle: () => void;
  setTrue: () => void;
  setFalse: () => void;
}

export function useToggleState(
  initialValue: boolean | (() => boolean),
): [boolean, Dispatch<SetStateAction<boolean>>, UseToggleStateCallbacks] {
  const [state, setState] = useState(initialValue);

  const toggle = useCallback(() => setState((prev) => !prev), []);
  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);

  return [state, setState, { toggle, setTrue, setFalse }];
}
