import { gql } from "@apollo/client";
import { DEFAULT_USER_SETTINGS, UserSettings } from "@hex/common";
import { useCallback } from "react";

import { INLINE_CODE_COMPLETION_PROVIDER } from "../components/cell/monaco/KernelCompletionProvider.js";

import { useUserForSettings } from "./me/useUserForSettings";
import { useSetUserSettingsMutation } from "./useUpdateUserSettings.generated";

gql`
  mutation setUserSettings($settings: UserSettings!) {
    updateUserSettings(settings: $settings) {
      id
      userSettings
    }
  }
`;

export const useUpdateUserSettings = (): {
  updateWordWrapMarkdown: (enabled: boolean) => void;
  updateWordWrapCode: (enabled: boolean) => void;
  updateWordWrapSql: (enabled: boolean) => void;
  updateLineNumbersMarkdown: (enabled: boolean) => void;
  updateLineNumbersSql: (enabled: boolean) => void;
  updateLineNumbersCode: (enabled: boolean) => void;
  updateMagicTypeaheadCode: (enabled: boolean) => void;
  updateMagicTypeaheadAndTos: (
    tosAccepted: boolean,
    typeaheadEnabled: boolean,
  ) => Promise<void>;
} => {
  const { id, userSettings } = useUserForSettings();
  const [setUserSettings] = useSetUserSettingsMutation();

  const updateUserSettings = useCallback(
    async (newSettings: UserSettings) => {
      if (id == null) return;
      await setUserSettings({
        variables: { settings: newSettings },
        optimisticResponse: {
          __typename: "Mutation",
          updateUserSettings: {
            id,
            __typename: "User",
            userSettings: newSettings,
          },
        },
      });
    },
    [id, setUserSettings],
  );

  const updateWordWrapMarkdown = useCallback(
    (enabled: boolean) =>
      void updateUserSettings({
        ...userSettings,
        wordWrap: {
          ...userSettings.wordWrap,
          markdown: enabled,
        },
      }),
    [userSettings, updateUserSettings],
  );

  const updateWordWrapSql = useCallback(
    (enabled: boolean) =>
      void updateUserSettings({
        ...userSettings,
        wordWrap: {
          ...userSettings.wordWrap,
          sql: enabled,
        },
      }),
    [userSettings, updateUserSettings],
  );

  const updateWordWrapCode = useCallback(
    (enabled: boolean) =>
      void updateUserSettings({
        ...userSettings,
        wordWrap: {
          ...userSettings.wordWrap,
          code: enabled,
        },
      }),
    [userSettings, updateUserSettings],
  );

  const updateLineNumbersMarkdown = useCallback(
    (enabled: boolean) =>
      void updateUserSettings({
        ...userSettings,
        lineNumbers: {
          ...userSettings.lineNumbers,
          markdown: enabled,
        },
      }),
    [userSettings, updateUserSettings],
  );

  const updateLineNumbersSql = useCallback(
    (enabled: boolean) =>
      void updateUserSettings({
        ...userSettings,
        lineNumbers: {
          ...userSettings.lineNumbers,
          sql: enabled,
        },
      }),
    [userSettings, updateUserSettings],
  );

  const updateLineNumbersCode = useCallback(
    (enabled: boolean) =>
      void updateUserSettings({
        ...userSettings,
        lineNumbers: {
          ...userSettings.lineNumbers,
          code: enabled,
        },
      }),
    [userSettings, updateUserSettings],
  );

  const updateMagicTypeaheadCode = useCallback(
    (enabled: boolean) => {
      INLINE_CODE_COMPLETION_PROVIDER.enableOrDisable(enabled);
      void updateUserSettings({
        ...userSettings,
        magicTypeahead: {
          ...(userSettings.magicTypeahead ??
            DEFAULT_USER_SETTINGS.magicTypeahead),
          code: enabled,
        },
      });
    },
    [userSettings, updateUserSettings],
  );

  const updateMagicTypeaheadAndTos = useCallback(
    async (tosAccepted: boolean, typeaheadEnabled: boolean) => {
      await updateUserSettings({
        ...userSettings,
        magicTypeahead: {
          ...userSettings.magicTypeahead,
          code: typeaheadEnabled,
          magicTosAcceptedJuly2024: tosAccepted,
        },
      });
    },
    [userSettings, updateUserSettings],
  );

  return {
    updateWordWrapMarkdown,
    updateWordWrapCode,
    updateWordWrapSql,
    updateLineNumbersMarkdown,
    updateLineNumbersSql,
    updateLineNumbersCode,
    updateMagicTypeaheadCode,
    updateMagicTypeaheadAndTos,
  };
};
